import React, { FC, memo } from 'react'
import Dialog from '@ucheba/ui/components/Dialog/desktop/Dialog'
import styled from 'styled-components'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/desktop'
import { CodeTextField } from '@ucheba/ui/components/CodeTextField/desktop'
import { Box } from '@ucheba/ui/components/Box/desktop'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { ErrorText } from '@ucheba/ui/components/ErrorText/desktop'
import { authPhoneCodeSelectors } from '@ucheba/store/auth/phone/code'
import { useSelector } from 'react-redux'
import content from '../../AppliactionForm/data.json'
import SC from '../../AppliactionForm/base/ApplicationForm.styles'
import { IPhoneConfirmDialogProps } from '../types'
import { usePhoneConfirmDialog } from '../bll/usePhoneConfirmDialog'

const { Block: BlockSC } = SC
const Block = styled(BlockSC)``

const ButtonSC = styled(Button)`
  padding: 0;
  height: auto;
`

const PhoneConfirmDialog: FC<IPhoneConfirmDialogProps> = (props) => {
  const { open, onClose, onSubmit, isLoading, phone } = props

  const error = useSelector(authPhoneCodeSelectors.error)

  const { timeLeft, isTimeLeft, onInputCode, getNewCode, isSendCodeTypeTelegram } =
    usePhoneConfirmDialog(phone, onSubmit)

  return (
    <Dialog open={open} onClose={onClose} overflow='auto' spacing='xlarge' size='small'>
      <Block>
        <Box color='white' spacing='null'>
          <Heading
            h1={
              isSendCodeTypeTelegram
                ? content.code.title.telegram
                : content.code.title.sms
            }
            spacing='small'
            textAlign='center'
          />

          <Text textAlign='center'>{`${content.code.phone.prevText} ${phone}`}</Text>

          {/* <Text textAlign='center' html={false}> */}
          {/*  <Link href={requestStep}> */}
          {/*    {content.code.phone.changeLinkText}</a> */}
          {/*  </Link> */}
          {/* </Text> */}

          <Spacing spacing='xlarge' />

          <Grid justifyContent='center'>
            <GridCol width='auto'>
              <CodeTextField onChange={onInputCode} disabled={isLoading} />
            </GridCol>
          </Grid>

          {/* <ErrorMessage component={ErrorText} textAlign='center' name={EDataKeys.code} /> */}

          <Spacing spacing='xlarge' />

          {isTimeLeft ? (
            <ButtonSC
              view='link'
              onClick={() => getNewCode(!isSendCodeTypeTelegram)}
              block
              size='small'
            >
              {content.code.getNewCodeLinkText}
            </ButtonSC>
          ) : (
            <Text textAlign='center' fontSize='small1' color='gray60'>
              {`${content.code.prevTextNewCode} ${timeLeft}`}
            </Text>
          )}
        </Box>

        {isSendCodeTypeTelegram && isTimeLeft && (
          <>
            <Spacing spacing='xlarge' />

            <ButtonSC
              view='link'
              color='blue'
              onClick={() => getNewCode(true)}
              progress={isLoading}
            >
              Получить новый код по СМС
            </ButtonSC>
          </>
        )}

        {error && (
          <>
            <Spacing spacing='xsmall' />

            <ErrorText>{error.message}</ErrorText>
          </>
        )}
      </Block>
    </Dialog>
  )
}

export default memo(PhoneConfirmDialog)
